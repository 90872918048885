import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Meta from '../components/Meta'
import Layout from '../components/Layout'
import './about.scss'

const AboutPage = ({ data, location }) => {
  const graphql = data.allPrismicAbout.edges[0].node.data
  return (
    <Layout>
      <Meta
        data={{
          pathName: location.pathname,
          title: 'About Studio WillemsPeeters',
          description: graphql.general_bio.text,
        }}
      />
      <div className="container container-compact">
        <div className="about">
          <div
            className="bio generaltext"
            dangerouslySetInnerHTML={{
              __html: graphql.general_bio.html,
            }}
          />
          <div className="bio jelena">
            <Image
              className="portrait"
              fluid={graphql.jelena_image.fluid}
              alt="Portrait of Jelena Peeters"
              backgroundColor
            />
            <h2>Jelena Peeters</h2>
            <div>{graphql.jelena_location}</div>
            <div>{graphql.jelena_phone}</div>
            <a href={`mailto:${graphql.jelena_email}`}>
              {graphql.jelena_email}
            </a>
            <div
              className="personaltext"
              dangerouslySetInnerHTML={{
                __html: graphql.jelena_bio.html,
              }}
            />
          </div>
          <div className="bio henk">
            <Image
              className="portrait"
              fluid={graphql.henk_image.fluid}
              alt="Portrait of Henk Willems"
              backgroundColor
            />
            <h2>Henk Willems</h2>
            <div>{graphql.henk_location}</div>
            <div>{graphql.henk_phone}</div>
            <a href={`mailto:${graphql.henk_email}`}>{graphql.henk_email}</a>
            <div
              className="personaltext"
              dangerouslySetInnerHTML={{
                __html: graphql.henk_bio.html,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query aboutQuery {
    allPrismicAbout {
      edges {
        node {
          data {
            general_bio {
              html
              text
            }
            jelena_bio {
              html
            }
            jelena_phone
            jelena_location
            jelena_email
            jelena_image {
              fluid(maxWidth: 504) {
                ...GatsbyPrismicImageFluid
              }
            }
            henk_bio {
              html
            }
            henk_phone
            henk_location
            henk_email
            henk_image {
              fluid(maxWidth: 504) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
